<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 7H7V9H15V7Z" :fill="fill" />
    <path d="M7 11H11V13H7V11Z" :fill="fill" />
    <path d="M12 15H7V17H12V15Z" :fill="fill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 2H19C20.6569 2 22 3.34315 22 5V15.5858C22 16.3814 21.6839 17.1445 21.1213 17.7071L17.7071 21.1213C17.1445 21.6839 16.3814 22 15.5858 22H5C3.34314 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2ZM5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H14V16C14 14.8954 14.8954 14 16 14H20V5C20 4.44772 19.5523 4 19 4H5ZM16.2929 19.7071C16.2074 19.7926 16.108 19.861 16 19.9102V16H19.9102C19.861 16.108 19.7926 16.2074 19.7071 16.2929L16.2929 19.7071Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
