<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 3C3.44772 3 3 3.44772 3 4V18C3 18.5523 3.44771 19 4 19H13V15C13 13.8954 13.8954 13 15 13H19V11H21V14.6716C21 15.4672 20.6839 16.2303 20.1213 16.7929L16.7929 20.1213C16.2303 20.6839 15.4672 21 14.6716 21H4C2.34315 21 1 19.6569 1 18V4C1 2.34315 2.34315 1 4 1H11V3H4ZM15.3787 18.7071C15.271 18.8148 15.1412 18.8954 15 18.9445V15H18.9445C18.8954 15.1412 18.8148 15.271 18.7071 15.3787L15.3787 18.7071Z"
      :fill="fill"
    />
    <path d="M16 6V3H18V6H21V8H18V11H16V8H13V6H16Z" :fill="fill" />
    <path d="M11 8H6V10H11V8Z" :fill="fill" />
    <path d="M6 12H10V14H6V12Z" :fill="fill" />
    <path d="M11 16H6V18H11V16Z" :fill="fill" />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
